import React, { ChangeEvent, FC } from "react";
import { useField } from "formik";
import style from "./style.module.scss";
import validationError from "../../assets/images/validationError.svg";

interface ICustomInput {
  name: string;
  type: string;
  label: string;
  placeholder?: string;
  hasFullWidth?: boolean;
  marginBottom?: string;
  regexp?: RegExp;
  width?: string;
  isDisabled?: boolean;
  withError?: boolean;
  onChange?: (str: string) => void;
  customIsTouched?: boolean;
  customError?: string;
}

export const CustomInput: FC<ICustomInput> = ({
  name,
  type,
  label,
  placeholder,
  hasFullWidth = false,
  marginBottom,
  regexp,
  width,
  isDisabled = false,
  withError = true,
  onChange,
  customError = "",
  customIsTouched = false,
}) => {
  const [field, meta] = useField({
    name,
    type,
  });

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) {
      return;
    }
    if (regexp) {
      if (e.currentTarget.value === "") {
        return field.onChange(e);
      }
      if (!regexp.test(e.currentTarget.value)) return;
    }

    if (onChange) {
      onChange(e.target.value);
    }
    field.onChange(e);
  };

  return (
    <div
      className={`${style.input_wrapper} ${
        hasFullWidth && style.fullWidthInput
      } ${
        (meta.touched || customIsTouched) &&
        (meta.error || customError) &&
        withError &&
        style.input_wrapperWithWarning
      }`}
      style={{
        marginBottom: marginBottom ? `${marginBottom}px` : "",
        width: width?.includes("%")
          ? `${width}`
          : width && !width?.includes("%")
          ? `${width}px`
          : "",
        opacity: isDisabled ? 0.5 : 1,
      }}
    >
      {label ? <label
        className={`${style.label} ${
          (meta.touched || customIsTouched) && (meta.error || customError) && !isDisabled && style.labelWithWarning
        }`}
      >
        {label}
      </label> : null}
      <input
        type={type}
        placeholder={placeholder}
        {...field}
        value={!field.value ? "" : field.value}
        className={`${style.customInput} ${
          isDisabled ? style.disabledCustomInput : ""
        } ${
          (meta.touched || customIsTouched) &&
          (meta.error || customError) &&
          !isDisabled &&
          style.customInputWithWarning
        }`}
        onBlur={field.onBlur}
        onChange={changeHandler}
      />
      {(meta.touched || customIsTouched) && (meta.error || customError) && !isDisabled && withError && (
        <div className={style.errorMessage}>{(meta.error || customError)}</div>
      )}
      {(meta.touched || customIsTouched) && (meta.error || customError) && !isDisabled && (
        <img
          src={validationError}
          alt="err"
          className={`${style.warningImg}`}
        />
      )}
    </div>
  );
};
